import React from "react";
import internationalApplicationPackage from "../../assets/enrolment/Overseas_Student_Enrolment_Form.pdf";
import InternationalFeeSchedule from "../../assets/enrolment/InternationalFeeSchedule.pdf";
import englishProficiencyRequirements from "../../assets/enrolment/English Proficiency Requirements.pdf";
import internationalStudentPolicyHandbook from "../../assets/enrolment/International Student Policy Handbook.pdf";
import parentalNominationForm from "../../assets/enrolment/Parental Nomination of Guardian and Homestay Form.pdf";
import requestFeeRefundForm from "../../assets/enrolment/Request of Fees Refund Form.pdf";
import requestToTransferForm from "../../assets/enrolment/Request to Transfer Form.pdf";
import agentList from "../../assets/enrolment/Agent List.pdf";
import agentAgreementApplication from "../../assets/enrolment/Education Agent Application.pdf";
import enrolmentBottomImage from "../../assets/enrolment/bottomEnrolment.jpg";
import SideBar from "../master/SideBar";
import ContentWrapper from "../master/ContentWrapper";
import {EnrolmentSidebarLink} from "../const";
import PageTitle from "../master/PageTittle";

const InternationalContent = () => (
    <div className="container">
        <ContentWrapper sidebar={true} narrow={true}>
            <div className={"content"}>
                <PageTitle title="Welcome" variant="h2"/>

                <p>
                    {" "}
                    Saint Maroun’s College is an Independent Catholic co-educational school founded by the Maronite
                    Sisters of the Holy Family, providing a holistic education for all years from Kindergarten to Year
                    12.
                </p>

                <p>At Saint Maroun’s College your child’s education is our priority.</p>

                <p>
                    We are a close-knit community with highly professional, qualified and experienced teachers dedicated
                    to deliver a vast range of educational programs with a balanced approach between academic, cultural,
                    physical and spiritual dimensions. The College has a nurturing, safe environment which celebrates
                    and develops your child’s strengths and aspirations. It also offers post school pathways into
                    university and the workplace.
                </p>

                <p>
                    To find out more information please do not hesitate to contact the College during school hours AEST
                    on +61 2 9559 2434 or email internationals@stmarouns.nsw.edu.au.
                </p>

                <p>
                    Karen Maluf
                    <br/>
                    Enrolments Officer
                </p>

                <PageTitle title="Application Process" variant="h3"/>
                <p>The process of student application is as follows:</p>
                <p>
                    Step 1: Application
                </p>
                <p>
                    An Application for Enrolment is submitted with the required documentation and payment of a
                    non-refundable application fee of $500. Required documentation:
                </p>
                <ul className="enrolmentContent">
                    <li>Birth Certificate of student</li>
                    <li>Passport of student and parent (if the parent has no passport a local drivers licence)</li>
                    <li>VISA</li>
                    <li>English Proficiency test results</li>
                    <li>Copies of students previous school reports</li>
                </ul>

                <p>
                    St Maroun’s College English proficiency requirements are outlined here:
                </p>
                <a
                    href={englishProficiencyRequirements}
                    type="button"
                    className="btn enrolmentFormButton"
                >
                    English Proficiency Requirements
                </a>

                <br/>
                <a
                    href={internationalApplicationPackage}
                    type="button"
                    className="btn enrolmentFormButton"
                >
                    International Student Application Form
                </a>
                <br/>
                <a
                    href={InternationalFeeSchedule}
                    type="button"
                    className="btn enrolmentFormButton"
                >
                    International Fee Schedule
                </a>

                <p>
                    You may choose to use one of our approved international agents. View the list of our approved
                    international agents.
                    <a href={agentList}>International Agents List</a>
                </p>
                <p>
                    Step 2: Interview and Conditional Offer
                </p>
                <p>
                    The application is reviewed and if the student’s English test results meet the College requirements,
                    the student will be invited to attend an in-house exam and interview with an Executive member of the
                    College.
                </p>
                <p>
                    If the student is overseas the interview will be conducted remotely.
                </p>
                <p>
                    The student may be offered a place or be given a conditional letter of offer which will state the
                    conditions that must be reached prior to entry. This usually includes a period of time at an ELICOS
                    College and the required English Proficiency requirements for commencing.
                </p>


                <p>
                    Step 3: Acceptance of Offer
                </p>
                <p>
                    Upon receiving a Letter of Offer, parents accept by signing the Letter of Offer and submitting that
                    with the non-refundable confirmation of enrolment fee.
                </p>

                <p>
                    Step 4: Orientation and commencement
                </p>
                <p>
                    All students enrolled to commence will be invited to attend an orientation session on the day before
                    they commence at the College with their parent/guardian or local guardian.
                </p>

                <p>
                    General Information & Policies
                </p>
                <p>
                    For further information on St Maroun’s College and its policies for overseas students download
                    the <a href={internationalStudentPolicyHandbook}>Overseas Student Handbook</a>
                </p>

                             <PageTitle title="Forms" variant="h3"/>

                <p>Important forms and documents for international students:</p>
                <ul className="enrolmentContent">
                    <li>
                        <a href={parentalNominationForm}>
                            Parental Nomination of Guardian and Homestay Form
                        </a>
                    </li>
                    <li>
                        <a href={requestFeeRefundForm}>Request of Fees Refund Form</a>
                    </li>
                    <li>
                        <a href={requestToTransferForm}>Request to Transfer Form</a>
                    </li>
                </ul>

                <PageTitle title="Education Agents" variant="h3"/>
                <ul className="enrolmentContent">
                    <li>
                        <a href={agentList}>Agents List</a>
                    </li>
                    <li>
                        <a href={agentAgreementApplication}>Agent Agreement Application</a>
                    </li>
                </ul>


            </div>
            <SideBar items={EnrolmentSidebarLink} title="Enrolments"/>
        </ContentWrapper>
        <img
            src={enrolmentBottomImage}
            className="img-fluid justify-content-center "
            height={600}
            alt="..."
        />
    </div>
);

export default InternationalContent;
